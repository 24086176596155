<script>
import { company_categories } from "@/config/api/company_categories";
export default {
  props: ["selectedCategory"],
  mounted() {
    console.log(company_categories);
  },
  data() {
    return {
      title: "Categories",
      items: [
        {
          text: "All Categories",
          to: { path: `/categories` },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      loading: false,
      values: {},
      schema: [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "Categories Name",
          "validation-messages": { required: "Categories name is required" },
        },
        {
          index: 2,
          type: "text",
          name: "name_ar",
          label: "Categories Name Arabic",
        },
        {
          index: 3,
          type: "range",
          name: "level",
          label: "Level",
          min: "1",
          max: "10",
          value: 1,
          showValue: true,
          validation: "required|min:1|max:10",
          "validation-messages": { required: "Level is required" },
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedCategory) {
        this.updateCategory(data, this.selectedCategory._id);
      } else {
        this.addCategory(data);
      }
    },
    addCategory(data) {
      this.loading = true;

      const api = company_categories.create_main;
      api.data = { ...data, level: parseInt(data.level) };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Category added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Category is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCategory(data, id) {
      this.loading = true;
      const api = company_categories.update_main;
      api.data = {
        name: data.name,
        name_ar: data.name_ar,
        level: parseInt(data.level),
      };
      api.id = id;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Category updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Category is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });
    },
  },
  watch: {
    selectedCategory(val) {
      if (val) {
        this.values = { ...val };
        this.schema[2].value = this.values.level;
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Category"
    size="xl"
    @hidden="$emit('resetModal')"
    :title="selectedCategory ? 'Update Category' : 'Add Category'"
    hide-footer
  >
    <FormulateForm
      name="CategoryAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="loading">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedCategory"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedCategory"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1650px !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 1200px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 950px !important;
  }
}
</style>
