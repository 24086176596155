<script>
import { company_categories } from "@/config/api/company_categories";
export default {
  props: ["selectedCategory", "categoryData", "currentCategory"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name EN",
          validation: "required",
        },
        {
          index: 0,
          type: "text",
          name: "name_ar",
          label: "Name AR",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          label: "Main Category",
          name: "main_category.name",
          value: this.currentCategory.name,
          disabled: true,
        },
        {
          index: 2,
          type: "checkbox",
          label: "Permissions",
          name: "permissions",
          options: {},
          value: [],
        },
      ],
    };
  },
  mounted() {
    this.getAllCompanyPermission();
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedCategory) {
        data.main_category = this.categoryData.find(
          (i) => i.main_category.name === this.values["main_category.name"]
        )._id;
        console.log(data.main_category);
        this.updateCategory(data, this.selectedCategory._id);
      } else {
        this.addCategory(data);
      }
    },
    addCategory(data) {
      delete data["main_category.name"];

      this.performAction = true;
      const api = company_categories.create_sub;
      api.data = {
        name: data.name,
        name_ar: data.name_ar,
        main_category: this.currentCategory._id,
        permissions: data.permissions,
      };
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("CategoryAction");
          this.$bvToast.toast("Category added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Category is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateCategory(data, id) {
      this.performAction = true;
      delete data["main_category.name"];
      const api = company_categories.update_sub;
      api.data = { name_ar: data.name_ar, name: data.name, permissions: data.permissions };
      api.id = id;
      console.log(data);
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Category updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Category is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    getAllCompanyPermission() {
      const api = company_categories.company_category_permission;

      this.generateAPI(api)
        .then((res) => {
          this.schema[2].options = res.data.categories.map((item) => {
            return {
              value: item._id,
              label: item.permission,
            };
          });
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {});
    },
  },
  watch: {
    selectedCategory(val) {
      if (val) {
        this.values = {
          ...val,
          permissions: val.permissions.map((item) => item._id),
        };
      }
    },
  },
};
</script>

<template>
  <b-modal
    :id="'action-Sub-Category_' + currentCategory._id"
    @hidden="$emit('resetModal')"
    :title="selectedCategory ? 'Update Sub-Category' : 'Add Sub-Category'"
    hide-footer
  >
    <FormulateForm
      name="CategoryAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedCategory"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedCategory"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>
