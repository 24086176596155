<script>
import appConfig from "@/app.config";
import { company_categories } from "@/config/api/company_categories";
import CategoryModal from "@/components/company_categories_sub/category-modal";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Admin | ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { CategoryModal },
  props: ["currentCategory", "categoriesData"],
  data() {
    return {
      categoryData: [],
      title: "SubCategory",
      selectedCategory: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "category", sortable: true },
        { key: "category_ar", sortable: true },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    categories() {
      let categories = [];
      this.categoriesData.forEach((element) => {
        categories.push({
          _id: element._id,
          name: element.name,
        });
      });
      return categories;
    },
    rows() {
      return this.categoryData.length;
    },

    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    deletedCategories() {
      return this.categoryData.filter((email) => email.deletedAt != null);
    },
    activeCategories() {
      return this.categoryData.filter((email) => email.deletedAt === null);
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.categoryData.length;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isBusy = true;
      const api = company_categories.get_sub;
      await this.generateAPI(api)
        .then((res) => {
          console.log(res);
          console.log(this.currentCategory);
          this.categoryData = res.data.categories.filter(
            (item) => item?.main_category?._id === this.currentCategory._id
            // (CategoriesBy) => CategoriesBy.country === this.currentCategory._id
          );
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editCategory(data) {
      this.selectedCategory = data;
      this.showActionModal();
    },
    deleteCategory(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = company_categories.delete_sub;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Category deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Category is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showActionModal() {
      this.$bvModal.show("action-Sub-Category_" + this.currentCategory._id);
    },
    hideActionModal() {
      this.$bvModal.hide("action-Sub-Category_" + this.currentCategory._id);
    },
  },
};
</script>

<template>
  <div class="row">
    <!-- Table -->
    <div class="card-body pt-0">
      <b-button id="addCategories" @click="showActionModal" pill variant="primary"
        >Add Categories</b-button
      >
      <!-- All Categories -->
      <b-tabs
        nav-class="nav-tabs-custom"
        v-model="activeTab"
        active-nav-item-class="font-weight-bold"
        active-tab-class="font-weight-bold"
        content-class="mt-3"
      >
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">All Categories</a>
          </template>
          <div class="table-responsive">
            <b-table
                                v-if="this.activeTab == 0"

              bordered
              striped
              :items="categoryData"
              :fields="fields"
              responsive="sm"
              :busy="isBusy"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
            >
              <template #table-busy>
                <div style="text-align: center" class="mt-5 mb-5">
                  <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                </div>
              </template>
              <template #cell(actions)="row">
                <button class="action-btn" @click="deleteCategory(row.item)">
                  <i class="ri-delete-bin-line"></i>
                </button>
                <button class="action-btn" @click="editCategory(row.item)">
                  <i class="ri-edit-line"></i>
                </button>
              </template>
              <template #cell(category)="row">
                {{ row.item.name }}
              </template>
              <template #cell(category_ar)="row">
                {{ row.item.name_ar }}
              </template>
              <template #cell(country)>
                {{ currentCategory.name }}
              </template>
              <template #cell(createdAt)="row">
                {{ moment(row.item.createdAt).format("l") }}
              </template>
              <template #cell(updatedAt)="row">
                {{ moment(row.item.updatedAt).format("l") }}
              </template>
              <template #cell(deletedAt)="row">
                {{
                  !row.item.deletedAt
                    ? ""
                    : moment(row.item.deletedAt).format("l")
                }}
              </template>
              <template #cell(deletedBy)="row">
                {{ !row.item.deletedBy ? "" : row.item.deletedBy.name }}
              </template>
            </b-table>
          </div>
        </b-tab>
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Active Categories</a>
          </template>
          <div class="table-responsive">
            <b-table
                                v-if="this.activeTab == 1"

              bordered
              striped
              :items="activeCategories"
              :fields="computedFields"
              responsive="sm"
              :busy="isBusy"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
            >
              <template #table-busy>
                <div style="text-align: center" class="mt-5 mb-5">
                  <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                </div>
              </template>
              <template #cell(actions)="row">
                <button class="action-btn" @click="deleteCategory(row.item)">
                  <i class="ri-delete-bin-line"></i>
                </button>
                <button class="action-btn" @click="editCategory(row.item)">
                  <i class="ri-edit-line"></i>
                </button>
              </template>
              <template #cell(category)="row">
                {{ row.item.name }}
              </template>
              <template #cell(country)>
                {{ currentCategory.name }}
              </template>
              <template #cell(createdAt)="row">
                {{ moment(row.item.createdAt).format("l") }}
              </template>
              <template #cell(updatedAt)="row">
                {{ moment(row.item.updatedAt).format("l") }}
              </template>
              <template #cell(deletedAt)="row">
                {{
                  !row.item.deletedAt
                    ? ""
                    : moment(row.item.deletedAt).format("l")
                }}
              </template>
              <template #cell(deletedBy)="row">
                {{ !row.item.deletedBy ? "" : row.item.deletedBy.name }}
              </template>
            </b-table>
          </div>
        </b-tab>
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Deleted Categories</a>
          </template>
          <div class="table-responsive">
            <b-table
                                v-if="this.activeTab == 2"

              bordered
              striped
              :items="deletedCategories"
              :fields="fields"
              responsive="sm"
              :busy="isBusy"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
            >
              <template #table-busy>
                <div style="text-align: center" class="mt-5 mb-5">
                  <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                </div>
              </template>
              <template #cell(actions)="row">
                <button class="action-btn" @click="deleteCategory(row.item)">
                  <i class="ri-delete-bin-line"></i>
                </button>
                <button class="action-btn" @click="editCategory(row.item)">
                  <i class="ri-edit-line"></i>
                </button>
              </template>
              <template #cell(category)="row">
                {{ row.item.name }}
              </template>
              <template #cell(country)>
                {{ currentCategory.name }}
              </template>
              <template #cell(createdAt)="row">
                {{ moment(row.item.createdAt).format("l") }}
              </template>
              <template #cell(updatedAt)="row">
                {{ moment(row.item.updatedAt).format("l") }}
              </template>
              <template #cell(deletedAt)="row">
                {{
                  !row.item.deletedAt
                    ? ""
                    : moment(row.item.deletedAt).format("l")
                }}
              </template>
              <template #cell(deletedBy)="row">
                {{ !row.item.deletedBy ? "" : row.item.deletedBy.name }}
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>
      <CategoryModal
        :currentCategory="currentCategory"
        :categoryData="categoryData"
        :selectedCategory="selectedCategory"
        v-on:resetModal="selectedCategory = null"
        v-on:reloadData="loadData"
        v-on:closeModal="hideActionModal"
      />
    </div>
  </div>
</template>

<style scoped>
#addCategories {
  float: right;
  margin-top: 10px;
}
.row {
  background-color: #f1f5f7;
}
</style>