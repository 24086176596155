<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { company_categories } from "@/config/api/company_categories";
import CategoryModal from "@/components/company_categories/category-modal";
import SubCategory from "@/views/pages/company_categories_sub";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Company",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, CategoryModal, SubCategory },
  data() {
    return {
      title: "Company Categories",
      breadcrumbs: [
        {
          text: "Company Categories",
          active: true,
        },
        {
          text: "",
          //   to: { name: "home" },
        },
      ],
      companyCategoryData: [],
      selectedCategory: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true },
        { key: "name_ar", sortable: true },
        { key: "actions", sortable: false },
        { key: "show_details", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    deletedCategories() {
      return this.companyCategoryData.filter((email) => email.deletedAt != null);
    },
    activeCategories() {
      return this.companyCategoryData.filter((email) => email.deletedAt === null);
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    // Set the initial number of items
  },
  methods: {
    toggleDetails(val)
    {
      val.toggleDetails();
      this.currentCategory(val);
    },
    async loadData(page = 1, search = "") {
      this.isBusy = true;
      const api = company_categories.get_main;
      api.params = {page: page, search: search}
      await this.generateAPI(api)
        .then((res) => {
          res.data.categories.docs.map(() => {
            // i["isActive"] = true;
          });
          this.companyCategoryData = res.data.categories.docs;
          this.totalRows = res.data.categories.totalDocs;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    updatePage(page)
    {
          this.loadData(page);

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editCategory(data) {
      this.selectedCategory = data;
      this.showActionModal();
    },
    deleteCategory(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = company_categories.delete_main;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Category deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Category is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    currentCategory(data) {
      return (this.selectedCategory = data);
    },
    showActionModal() {
      console.log("test");
      this.$bvModal.show("action-Category");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Category");
    },
    addCategory() {
      this.selectedCategory = null;
       this.showActionModal();
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button
              id="addCategories"
              @click="addCategory"
              pill
              variant="primary"
              >Add Category</b-button
            >
            <!-- All Category -->
            <b-tabs
              nav-class="nav-tabs-custom"
              v-model="activeTab"
              active-nav-item-class="font-weight-bold"
              active-tab-class="font-weight-bold"
              content-class="mt-3"
            >
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Categories</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    v-if="this.activeTab == 0"
                    :items="companyCategoryData"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button
                        pill
                        variant="primary"
                        size="sm"
                        @click="
                          toggleDetails(row)
                        "
                        class="mr-2"
                      >
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>
                    <template #row-details="row">
                      <b-card>
                        <SubCategory
                          :currentCategory="row.item"
                          :categoriesData="companyCategoryData"
                        />
                      </b-card>
                    </template>
                    <template #cell(category)="row">
                      {{ row.item.name }}
                    </template>
                    <template #cell(actions)="row">
                      <button
                        class="action-btn"
                        @click="deleteCategory(row.item)"
                      >
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button class="action-btn" @click="editCategory(row.item)">
                        <i class="ri-edit-line"></i>
                      </button>
                    </template>
                   
                    <template #cell(deletedAt)="row">
                      {{
                        row.item.deletedAt !== null
                          ? moment(row.item.deletedAt).format("l")
                          : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                        row.item.deletedBy !== null
                          ? row.item.deletedBy.name
                          : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          @change="updatePage"

                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Active Categories</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    v-if="this.activeTab == 1"
                    :items="activeCategories"
                    :fields="computedFields"
                    responsive="sm"
                    :busy="isBusy"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button
                        pill
                        variant="primary"
                        size="sm"
                        @click="
                                                    toggleDetails(row)

                        "
                        class="mr-2"
                      >
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>
                    <template #row-details="row">
                      <b-card>
                        <SubCategory
                          :currentCategory="row.item"
                          :categoriesData="companyCategoryData"
                        />
                      </b-card>
                    </template>
                    
                    <template #cell(actions)="row">
                      <button
                        class="action-btn"
                        @click="deleteCategory(row.item)"
                      >
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button class="action-btn" @click="editCategory(row.item)">
                        <i class="ri-edit-line"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                        row.item.deletedAt !== null
                          ? moment(row.item.deletedAt).format("l")
                          : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                        row.item.deletedBy !== null
                          ? row.item.deletedBy.name
                          : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          @change="updatePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Deleted Categories</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    v-if="this.activeTab == 2"
                    :items="deletedCategories"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button
                        pill
                        variant="primary"
                        size="sm"
                        @click="
                                                    toggleDetails(row)

                        "
                        class="mr-2"
                      >
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>
                    <template #row-details="row">
                      <b-card>
                        <SubCategory
                          :currentCategory="row.item"
                          :categoriesData="companyCategoryData"
                        />
                      </b-card>
                    </template>
                  
                    <template #cell(actions)="row">
                      <button
                        class="action-btn"
                        @click="deleteCategory(row.item)"
                      >
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button class="action-btn" @click="editCategory(row.item)">
                        <i class="ri-edit-line"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                        row.item.deletedAt !== null
                          ? moment(row.item.deletedAt).format("l")
                          : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                        row.item.deletedBy !== null
                          ? row.item.deletedBy.name
                          : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <CategoryModal
      :selectedCategory="selectedCategory"
      v-on:resetModal="selectedCategory = null"
      v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal"
    />
  </Layout>
</template>

<style scoped>
#addCategories {
  float: right;
  margin-top: 10px;
}
</style>